import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  getCode (store, credentials) {
    Vue.$logger.debug('auth-sms-code | Requesting SMS code')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/auth/sms/code'

    return axios.post(ACTION_URL, credentials)
      .then((response) => {
        Vue.$logger.debug('auth-sms-code | Requested SMS code')
        if (typeof response.data.code !== 'undefined') {
          Vue.$logger.info('auth-sms-code | Code for login: ' + response.data.code)
        }
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('auth-sms-code | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 415) {
            throw new Error('wrong_phone_format')
          } else if (error.response.data.code) {
            throw new Error(error.response.data.code)
          } else {
            throw new Error('auth_error')
          }
        } else if (error.request) {
          Vue.$logger.error('auth-sms-code', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  login (store, credentials) {
    Vue.$logger.debug('auth-sms-code | Signing in with phone and sms code')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/auth/sms/login'

    return axios.post(ACTION_URL, credentials)
      .then((response) => {
        Sentry.setTag('rewards-app', store.state.main.code)
        Sentry.setTag('user-phone', credentials.phone)
        Vue.$logger.info('auth-sms-code | User token: ' + response.data.token)
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('auth-sms-code | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('wrong_sms_code')
          } else {
            throw new Error('auth_error')
          }
        } else if (error.request) {
          Vue.$logger.error('auth-sms-code', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
